import React, { useState, useEffect } from 'react';

const LoadingScreen = ({ onLoadingComplete, theme }) => {
  const [memojiIndex, setMemojiIndex] = useState(0);
  const roles = ['Developer', 'Engineer', 'Cloud Expert']; // Example roles

  const memojis = [
    '/assets/memoji-1.png',
    '/assets/memoji-2.png',
    '/assets/memoji-3.png',
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setMemojiIndex((prevIndex) => (prevIndex + 1) % memojis.length);
    }, 200);

    const timeout = setTimeout(() => {
      clearInterval(interval);
      onLoadingComplete();
    }, 3000);

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, [onLoadingComplete, memojis.length]);

  return (
    <div className={`loading-screen ${theme}`}>
      <img src={memojis[memojiIndex]} alt="Loading" className="loading-memoji" />
      <p className="loading-role">{roles[memojiIndex]}</p> {/* Display role */}
    </div>
  );
};

export default LoadingScreen;
