import React from 'react';
import ReactDOM from 'react-dom/client';  // Corrected import
import './index.css';
import App from './App';
import { ThemeProvider } from './ThemeContext';

// Get the root element from your HTML
const rootElement = document.getElementById('root');

// Create a root.
const root = ReactDOM.createRoot(rootElement);

// Render your app
root.render(
  <React.StrictMode>
    <ThemeProvider>
      <App />
    </ThemeProvider>
  </React.StrictMode>
);
