import React, { createContext, useState, useEffect } from 'react';

const ThemeContext = createContext();

const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState('light');

  // Utility function to check storage availability
  const storageAvailable = (type) => {
    try {
      const storage = window[type];
      const testKey = '__storage_test__';
      storage.setItem(testKey, testKey);
      storage.removeItem(testKey);
      return true;
    } catch (e) {
      return false;
    }
  };

  useEffect(() => {
    let savedTheme = null;
    try {
      if (storageAvailable('sessionStorage')) {
        savedTheme = sessionStorage.getItem('theme');
      } else {
        console.warn('Session storage not available');
      }
    } catch (error) {
      console.error('Session storage access error:', error);
    }

    if (savedTheme) {
      setTheme(savedTheme);
    }
  }, []);

  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    try {
      if (storageAvailable('sessionStorage')) {
        sessionStorage.setItem('theme', newTheme);
      } else {
        console.warn('Session storage not available');
      }
    } catch (error) {
      console.error('Failed to store theme in sessionStorage:', error);
    }
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export { ThemeContext, ThemeProvider };
