import React, { useState, useEffect, useContext } from 'react';
import { ThemeContext } from './ThemeContext';
import { FaTwitter, FaGithub, FaLinkedin, FaInstagram } from 'react-icons/fa';
import LoadingScreen from './LoadingScreen';
import './App.css';

function App() {
  const { theme, toggleTheme } = useContext(ThemeContext);
  const [loading, setLoading] = useState(true);
  const [showLoadingScreen, setShowLoadingScreen] = useState(false);

  const socialLinks = {
    twitter: 'https://twitter.com/suleimanodetoro',
    github: 'https://github.com/suleimanodetoro',
    linkedin: 'https://linkedin.com/in/suleimanodetoro',
    instagram: 'https://instagram.com/ossphotography',
  };

  // Utility function to check storage availability
  const storageAvailable = (type) => {
    try {
      const storage = window[type];
      const testKey = '__storage_test__';
      storage.setItem(testKey, testKey);
      storage.removeItem(testKey);
      return true;
    } catch (e) {
      return false;
    }
  };

  useEffect(() => {
    let hasVisited = null;
    try {
      if (storageAvailable('localStorage')) {
        hasVisited = localStorage.getItem('hasVisited');
      } else {
        console.warn('Local storage not available');
      }
    } catch (error) {
      console.error('Local storage access error:', error);
    }

    if (!hasVisited) {
      setShowLoadingScreen(true);
      try {
        if (storageAvailable('localStorage')) {
          localStorage.setItem('hasVisited', 'true');
        } else {
          console.warn('Local storage not available');
        }
      } catch (error) {
        console.error('Failed to store hasVisited in localStorage:', error);
      }
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    document.body.className = theme;
  }, [theme]);

  const handleLoadingComplete = () => {
    setLoading(false); // Disable loading once complete
    setShowLoadingScreen(false); // Hide loading screen
  };

  if (loading && showLoadingScreen) {
    return <LoadingScreen onLoadingComplete={handleLoadingComplete} theme={theme} />;
  }

  return (
    <div className={`content-wrap ${loading ? 'hidden' : ''}`}>
      {/* Hide content during loading */}
      <div className="main-content">
        <img
          onClick={toggleTheme}
          className="profile-image"
          src={theme === 'dark' ? '/assets/memoji-dark.png' : '/assets/memoji-light.png'}
          alt="Suleiman's Memoji"
        />
        <div className="h1-container">
          <h1>Hi, I'm Suleiman,</h1>
          <h1>a Software Engineer</h1>
          <h1>in England🏴󠁧󠁢󠁥󠁮󠁧󠁿.</h1>
        </div>
        {/* <p>Crafting Code, Shaping Solutions, one line at a time</p> */}
        <p>
          I'm either <a href={socialLinks.twitter}>tweeting</a> random thoughts, pushing{' '}
          <a href={socialLinks.github}>code</a> on my side projects, or taking pictures of life's
          moments on <a href={socialLinks.instagram}>Instagram</a>. Occasionally, I put down my
          thoughts in longer form on my <a href="https://medium.com/@suleimanodetoro">Medium blog</a>.
        </p>
        <p>
          Got a project in mind?{' '}
          <a href="mailto:hello@suleimanodetoro.com">hello@suleimanodetoro.com</a> 🙋🏿
        </p>
        <p>Psst! Clicked the memoji yet?</p>
        <div className="social-links">
          <a href={socialLinks.twitter}>
            <FaTwitter />
          </a>
          <a href={socialLinks.github}>
            <FaGithub />
          </a>
          <a href={socialLinks.linkedin}>
            <FaLinkedin />
          </a>
          <a href={socialLinks.instagram}>
            <FaInstagram />
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;
